<template>
  <div>
    <div v-if="loadingDetail !== 'error'">
      <back-navigation title="Detail Item">
        <template
          #action
        >
          <b-button
            v-if="itemData.data_sheet"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="ml-auto"
            @click="$refs.pdf.show(itemData.data_sheet)"
          >
            Data Sheet
          </b-button>
          <b-button
            v-if="!loadingDetail"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="!permission.itemEdit"
            :to="{ name: 'item-edit', params: { id: id.toString() }}"
            variant="primary"
            class="ml-50"
            :class="{ 'ml-auto' : !itemData.data_sheet }"
          >
            Edit
          </b-button>
        </template>
      </back-navigation>
      <div
        v-if="loadingDetail"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <XyzTransitionGroup
        v-else
        appear
        xyz="fade left-3 stagger-3 delay-3"
      >
        <b-row
          key="2"
          class="match-height"
        >
          <b-col
            cols="12"
            xl="8"
          >
            <b-card>
              <h5 class="m-0 font-weight-bolder">
                {{ itemData.name }}
              </h5>
              <small>{{ itemData.no }} {{ itemData.liter ? `(${itemData.liter} Liter)` : '' }}</small>
              <div
                v-if="itemData.color.eng_name"
                class="d-flex align-items-center mt-1 mb-1"
              >
                <div
                  class="customswatch mr-50"
                  :style="`background-color: #${itemData.color.hex_code};`"
                />
                <div>
                  <h6 class="m-0">
                    {{ itemData.color.eng_name }}
                  </h6>
                  <small>{{ itemData.color.eng_name }}</small>
                </div>
              </div>
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <td>
                    <span>Tipe</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ itemData.type.name }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Kategori</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ itemData.itemCategory.name }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>VS</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ itemData.vs_volume_solid }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Balance</span>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ itemData.balance }}</span>
                  </td>
                </tr>
              </table>
            </b-card>
            <b-card>
              <item-performance :id="+id" />
            </b-card>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-card>
              <h4 class="m-0">
                Kategori Harga
              </h4>
              <small
                v-if="permission.viewHpp && itemData.hpp !== null"
              >
                HPP : {{ formatCurrency(itemData.hpp) }}
              </small>
              <div class="d-flex justify-content-between mt-50">
                <small
                  class="text-muted cursor-pointer"
                  @click="sortItems = {
                    by: 'cate',
                    sort: sortItems.by !== 'cate' ? 'asc' : sortItems.sort === 'asc' ? 'desc' : 'asc'
                  }"
                >
                  Kategori
                  <span v-if="sortItems.by === 'cate'">
                    <feather-icon
                      v-if="sortItems.sort === 'asc'"
                      icon="ArrowUpIcon"
                    />
                    <feather-icon
                      v-else
                      icon="ArrowDownIcon"
                    />
                  </span>
                </small>
                <small
                  class="text-muted cursor-pointer"
                  @click="sortItems = {
                    by: 'price',
                    sort: sortItems.by !== 'price' ? 'asc' : sortItems.sort === 'asc' ? 'desc' : 'asc'
                  }"
                >
                  Harga
                  <span v-if="sortItems.by === 'price'">
                    <feather-icon
                      v-if="sortItems.sort === 'asc'"
                      icon="ArrowUpIcon"
                    />
                    <feather-icon
                      v-else
                      icon="ArrowDownIcon"
                    />
                  </span>
                </small>
              </div>
              <XyzTransitionGroup
                appear
                class="mb-50"
                xyz="fade stagger-1 left-2"
                mode="out-in"
              >
                <div
                  v-for="(item, index) in sellingPrice"
                  :key="index"
                  class="d-flex justify-content-between align-items-center mt-50"
                >
                  <h6>
                    {{ item.priceCategory.name }}
                  </h6>
                  <h6>
                    {{ formatCurrency(item.price) }}
                  </h6>
                </div>
              </XyzTransitionGroup>
            </b-card>
          </b-col>
        </b-row>
        <b-card
          key="3"
        >
          <item-chart
            :item-id="+id"
          />
        </b-card>
      </XyzTransitionGroup>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BSpinner, BButton,
} from 'bootstrap-vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import { mapActions, mapGetters } from 'vuex'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import { formatCurrency } from '@core/utils/filter'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import ItemPerformance from './ItemPerformance.vue'
import ItemChart from './ItemChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    BButton,
    BackNavigation,
    ErrorEndpoint,
    ItemPerformance,
    PdfPreview,
    ItemChart,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingDetail: true,
      itemData: '',
      error: '',
      sortItems: { by: 'price', sort: 'asc' },
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    sellingPrice() {
      const price = this.itemData.detailSellingPrice

      if (this.itemData && price.length) {
        if (this.sortItems.by === 'price') {
          return price.sort((a, b) => (this.sortItems.sort === 'asc' ? a.price - b.price : b.price - a.price))
        }

        return price.sort((a, b) => (this.sortItems.sort === 'asc' ? a.priceCategory.name.localeCompare(b.priceCategory.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }) : b.priceCategory.name.localeCompare(a.priceCategory.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })))
      }

      return []
    },
  },
  created() {
    this.getItemsDetail(Number(this.id)).then(result => {
      this.itemData = result
      this.loadingDetail = false
    }).catch(error => {
      this.loadingDetail = 'error'
      this.error = `${error.graphQLErrors[0].message}`
    })
  },
  methods: {
    formatCurrency,
    ...mapActions(['getItemsDetail']),
  },
}
</script>

<style scoped>
.customswatch{
  width: 36px;
  height: 36px;
  border-radius: 4px;
}
</style>
