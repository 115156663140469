<template>
  <div>
    <div
      class="d-flex"
      :class="mode === 'row' ? 'flex-row' : 'flex-column'"
    >
      <b-card
        no-body
        border-variant="light"
        class="p-75 m-0 flex-fill"
        :class="mode === 'row' ? 'mr-1' : 'mb-75'"
      >
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="font-weight-bolder m-0 mr-25">
            {{ performance.quote.approved }}
          </h5>
          <Lottie
            v-if="performance.quote.previous_approved && performance.quote.approved !== performance.quote.previous_approved"
            v-b-popover.hover.top="`Periode sebelumnya : ${performance.quote.previous_approved}`"
            class="m-0"
            :options="{
              animationData: performance.quote.approved > performance.quote.previous_approved ? arrowUp : arrowDown
            }"
            :height="22"
            :width="22"
          />
          <b-badge
            v-b-popover.hover.top="'Quote Diapprove'"
            class="ml-auto"
            variant="info"
          >
            Approved
          </b-badge>
        </div>
        <div class="d-flex align-items-center mt-25">
          <h5 class="font-weight-bolder m-0 mr-25">
            {{ performance.quote.so }}
          </h5>
          <Lottie
            v-if="performance.quote.previous_so && performance.quote.so !== performance.quote.previous_so"
            v-b-popover.hover.top="`Periode sebelumnya : ${performance.quote.previous_so}`"
            class="m-0"
            :options="{
              animationData: performance.quote.so > performance.quote.previous_so ? arrowUp : arrowDown
            }"
            :height="22"
            :width="22"
          />
          <b-badge
            v-b-popover.hover.top="'Quote Sales Order'"
            class="ml-auto"
            variant="success"
          >
            S.O
          </b-badge>
        </div>
        <small class="font-weight-bolder text-center mt-50">Quote</small>
      </b-card>
      <b-card
        no-body
        border-variant="light"
        class="p-75 mb-1 mb-lg-0 flex-fill"
      >
        <div class="d-flex align-items-center">
          <h5 class="font-weight-bolder m-0 mr-25">
            {{ shortNumber(performance.value.approved) }}
          </h5>
          <Lottie
            v-if="performance.value.previous_approved && performance.value.approved !== performance.value.previous_approved"
            v-b-popover.hover.top="`Periode sebelumnya : ${shortNumber(performance.value.previous_approved)}`"
            class="m-0"
            :options="{
              animationData: performance.value.approved > performance.value.previous_approved ? arrowUp : arrowDown
            }"
            :height="22"
            :width="22"
          />
          <b-badge
            v-b-popover.hover.top="'Quote Diapprove'"
            class="ml-auto"
            variant="info"
          >
            Approved
          </b-badge>
        </div>
        <div class="d-flex align-items-center mt-25">
          <h5 class="font-weight-bolder m-0 mr-25">
            {{ shortNumber(performance.value.so) }}
          </h5>
          <Lottie
            v-if="performance.value.previous_so && performance.value.so !== performance.value.previous_so"
            v-b-popover.hover.top="`Periode sebelumnya : ${shortNumber(performance.value.previous_so)}`"
            class="m-0"
            :options="{
              animationData: performance.value.so > performance.value.previous_so ? arrowUp : arrowDown
            }"
            :height="22"
            :width="22"
          />
          <b-badge
            v-b-popover.hover.top="'Quote Sales Order'"
            class="ml-auto"
            variant="success"
          >
            S.O
          </b-badge>
        </div>
        <small class="font-weight-bolder text-center mt-50">Jumlah</small>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BBadge, VBPopover,
} from 'bootstrap-vue'
import Lottie from 'vue-lottie'
import arrowUp from '@/assets/images/lottie/arrow-up.json'
import arrowDown from '@/assets/images/lottie/arrow-down.json'
import { shortNumber } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BBadge,
    Lottie,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    performanceData: {
      type: Array,
      required: true,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'column',
    },
  },
  data() {
    return {
      arrowUp,
      arrowDown,
      performance: {
        quote: {
          previous_approved: 0,
          previous_so: 0,
          approved: 0,
          so: 0,
        },
        value: {
          previous_approved: 0,
          previous_so: 0,
          approved: 0,
          so: 0,
        },
      },
    }
  },
  mounted() {
    if (this.performanceData && this.performanceData.length) {
      this.performanceData.forEach(per => {
        if (per.period && per.period.toLowerCase() === 'recent') {
          if (per.type === 3) {
            this.performance.quote.approved = per.quote ?? 0
            this.performance.value.approved = per.value ?? 0
          } else {
            this.performance.quote.so = per.quote ?? 0
            this.performance.value.so = per.value ?? 0
          }
        } else if (per.period && per.period.toLowerCase() === 'previous') {
          if (per.type === 3) {
            this.performance.quote.previous_approved = per.quote ?? 0
            this.performance.value.previous_approved = per.value ?? 0
          } else {
            this.performance.quote.previous_so = per.quote ?? 0
            this.performance.value.previous_so = per.value ?? 0
          }
        }
      })
    }
  },
  methods: {
    shortNumber,
  },
}
</script>

<style>

</style>
