var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex",class:_vm.mode === 'row' ? 'flex-row' : 'flex-column'},[_c('b-card',{staticClass:"p-75 m-0 flex-fill",class:_vm.mode === 'row' ? 'mr-1' : 'mb-75',attrs:{"no-body":"","border-variant":"light"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',{staticClass:"font-weight-bolder m-0 mr-25"},[_vm._v(" "+_vm._s(_vm.performance.quote.approved)+" ")]),(_vm.performance.quote.previous_approved && _vm.performance.quote.approved !== _vm.performance.quote.previous_approved)?_c('Lottie',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(("Periode sebelumnya : " + (_vm.performance.quote.previous_approved))),expression:"`Periode sebelumnya : ${performance.quote.previous_approved}`",modifiers:{"hover":true,"top":true}}],staticClass:"m-0",attrs:{"options":{
            animationData: _vm.performance.quote.approved > _vm.performance.quote.previous_approved ? _vm.arrowUp : _vm.arrowDown
          },"height":22,"width":22}}):_vm._e(),_c('b-badge',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Quote Diapprove'),expression:"'Quote Diapprove'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-auto",attrs:{"variant":"info"}},[_vm._v(" Approved ")])],1),_c('div',{staticClass:"d-flex align-items-center mt-25"},[_c('h5',{staticClass:"font-weight-bolder m-0 mr-25"},[_vm._v(" "+_vm._s(_vm.performance.quote.so)+" ")]),(_vm.performance.quote.previous_so && _vm.performance.quote.so !== _vm.performance.quote.previous_so)?_c('Lottie',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(("Periode sebelumnya : " + (_vm.performance.quote.previous_so))),expression:"`Periode sebelumnya : ${performance.quote.previous_so}`",modifiers:{"hover":true,"top":true}}],staticClass:"m-0",attrs:{"options":{
            animationData: _vm.performance.quote.so > _vm.performance.quote.previous_so ? _vm.arrowUp : _vm.arrowDown
          },"height":22,"width":22}}):_vm._e(),_c('b-badge',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Quote Sales Order'),expression:"'Quote Sales Order'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" S.O ")])],1),_c('small',{staticClass:"font-weight-bolder text-center mt-50"},[_vm._v("Quote")])]),_c('b-card',{staticClass:"p-75 mb-1 mb-lg-0 flex-fill",attrs:{"no-body":"","border-variant":"light"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"font-weight-bolder m-0 mr-25"},[_vm._v(" "+_vm._s(_vm.shortNumber(_vm.performance.value.approved))+" ")]),(_vm.performance.value.previous_approved && _vm.performance.value.approved !== _vm.performance.value.previous_approved)?_c('Lottie',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(("Periode sebelumnya : " + (_vm.shortNumber(_vm.performance.value.previous_approved)))),expression:"`Periode sebelumnya : ${shortNumber(performance.value.previous_approved)}`",modifiers:{"hover":true,"top":true}}],staticClass:"m-0",attrs:{"options":{
            animationData: _vm.performance.value.approved > _vm.performance.value.previous_approved ? _vm.arrowUp : _vm.arrowDown
          },"height":22,"width":22}}):_vm._e(),_c('b-badge',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Quote Diapprove'),expression:"'Quote Diapprove'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-auto",attrs:{"variant":"info"}},[_vm._v(" Approved ")])],1),_c('div',{staticClass:"d-flex align-items-center mt-25"},[_c('h5',{staticClass:"font-weight-bolder m-0 mr-25"},[_vm._v(" "+_vm._s(_vm.shortNumber(_vm.performance.value.so))+" ")]),(_vm.performance.value.previous_so && _vm.performance.value.so !== _vm.performance.value.previous_so)?_c('Lottie',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(("Periode sebelumnya : " + (_vm.shortNumber(_vm.performance.value.previous_so)))),expression:"`Periode sebelumnya : ${shortNumber(performance.value.previous_so)}`",modifiers:{"hover":true,"top":true}}],staticClass:"m-0",attrs:{"options":{
            animationData: _vm.performance.value.so > _vm.performance.value.previous_so ? _vm.arrowUp : _vm.arrowDown
          },"height":22,"width":22}}):_vm._e(),_c('b-badge',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Quote Sales Order'),expression:"'Quote Sales Order'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" S.O ")])],1),_c('small',{staticClass:"font-weight-bolder text-center mt-50"},[_vm._v("Jumlah")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }