<template>
  <div>
    <b-tabs pills>
      <b-tab
        title="Bulanan"
        @click="fetchPerformance('M')"
      >
        <b-overlay
          :show="performance.M ? false : true"
          style="min-height: 80px"
        >
          <performance-card
            v-if="performance.M"
            :performance-data="performance.M"
            mode="row"
          />
        </b-overlay>
      </b-tab>
      <b-tab
        title="Semester"
        @click="fetchPerformance('S')"
      >
        <b-overlay
          :show="performance.S ? false : true"
          style="min-height: 80px"
        >
          <performance-card
            v-if="performance.S"
            :performance-data="performance.S"
            mode="row"
          />
        </b-overlay>
      </b-tab>
      <b-tab
        title="Tahunan"
        @click="fetchPerformance('Y')"
      >
        <b-overlay
          :show="performance.Y ? false : true"
          style="min-height: 80px"
        >
          <performance-card
            v-if="performance.Y"
            :performance-data="performance.Y"
            mode="row"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import PerformanceCard from '../../components/misc/PerformanceCard.vue'

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    PerformanceCard,
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      performance: {
        M: '',
        S: '',
        Y: '',
      },
    }
  },
  mounted() {
    this.fetchPerformance('M')
  },
  methods: {
    fetchPerformance(period) {
      if (!this.performance[period]) {
        this.$store.dispatch('getPerformanceQuotesItem', {
          item_id: this.id,
          period,
        }).then(result => {
          this.performance[period] = result
        })
      }
    },
  },
}
</script>

<style>

</style>
